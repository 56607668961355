<template>
  <b-card>
    <h3
      id="h.mg86hbuwqpwt"
      class="c6"
    >
      <span class="c10">Menambah tanggap darurat </span>
    </h3>
    <p class="c18 c16">
      <span class="c1" />
    </p>
    <ul class="c3 lst-kix_list_2-0 start">
      <li class="c14 c5 li-bullet-0">
        <span class="c1">Perhatikan menu sidebar klik &ldquo;Tanggap Darurat&rdquo; untuk memunculkan list sub menu-nya, kemudian pilih dan klik pada &ldquo;Tambah Tanggap Darurat&rdquo;, tunggu sebentar agar web mengalihkan ke halaman form tanggap darurat</span>
      </li>
    </ul>
    <p class="c14">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 517.97px; height: 264.24px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image8.png')"
          style="width: 517.97px; height: 264.24px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c18 c16 c19">
      <span class="c1" />
    </p>
    <hr style="page-break-before:always;display:none;">
    <p class="c18 c16">
      <span class="c1" />
    </p>
    <ul class="c3 lst-kix_list_2-0">
      <li class="c14 c5 li-bullet-0">
        <span class="c1">Setelah anda berada pada halaman form tanggap darurat</span>
      </li>
    </ul>
    <p class="c18 c19">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 432.01px; height: 218.78px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image10.png')"
          style="width: 432.01px; height: 218.78px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c18 c19">
      <span class="c1">Isi form dengan cermat karena ada beberapa form yang harus diisi (mandatori) dan bisa diabaikan (optional)</span>
    </p>
    <p class="c18 c19">
      <span class="c1">List input form, sebagai berikut:</span>
    </p>
    <ol
      class="c3 lst-kix_list_3-1 start"
      start="1"
    >
      <li class="c8 c5 li-bullet-0">
        <span class="c2">Perihal ( mandatori )</span>
      </li>
    </ol>
    <p class="c8 c12">
      <span class="c2">Input ini bersifat mandatori , Contoh input :</span>
    </p>
    <p class="c8">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 339.71px; height: 40.03px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image9.png')"
          style="width: 339.71px; height: 40.03px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c8">
      <span class="c2">Akan muncul error kalau tidak diisi, contoh :</span>
    </p>
    <p class="c8 c12">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 350.06px; height: 50.45px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image12.png')"
          style="width: 350.06px; height: 50.45px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span><span class="c2">&nbsp;</span>
    </p>
    <ol
      class="c3 lst-kix_list_3-1"
      start="2"
    >
      <li class="c8 c5 li-bullet-0">
        <span class="c2">&nbsp;Kategori ( optional )</span>
      </li>
    </ol>
    <p class="c8 c21">
      <span class="c2">Input ini bersifat optional, bisa diabaikan. Untuk mengisinya anda tinggal memilih list yang tersedia, klik input form kemudian pilih :</span>
    </p>
    <p class="c8 c12">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 348.51px; height: 40.29px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image11.png')"
          style="width: 348.51px; height: 40.29px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c8 c12">
      <span class="c2">Untuk menghapus klik tanda silang pada input form, contoh :</span>
    </p>
    <p class="c8 c12">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 351.03px; height: 42.34px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image14.png')"
          style="width: 351.03px; height: 42.34px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c8">
      <span class="c2">Input akan ter-reset kembali, contoh :</span>
    </p>
    <p class="c8">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 346.24px; height: 37.80px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image13.png')"
          style="width: 346.24px; height: 37.80px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <ol
      class="c3 lst-kix_list_3-1"
      start="3"
    >
      <li class="c5 c8 li-bullet-0">
        <span class="c2">&nbsp;Tanggal Insiden &nbsp;( mandatori )</span>
      </li>
    </ol>
    <p class="c8 c12">
      <span class="c2">Input bersifat mandatori. Untuk mengisinya anda tinggal memilih nomor tanggalnya saja, contoh :</span>
    </p>
    <p class="c8 c12">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 316.48px; height: 217.82px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image16.png')"
          style="width: 316.48px; height: 217.82px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c8">
      <span class="c2">Untuk meresetnya anda harus memilih pada tanggal lain.</span>
    </p>
    <ol
      class="c3 lst-kix_list_3-1"
      start="4"
    >
      <li class="c0 c5 li-bullet-0">
        <span class="c2">&nbsp;Tanggal Bantuan ( optional )</span>
      </li>
    </ol>
    <p class="c8 c12">
      <span class="c2">Input ini bersifat optional, untuk cara mengisinya sama dengan input tanggal insiden.</span>
    </p>
    <p class="c8 c12">
      <span class="c9">&nbsp;</span><span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 297.40px; height: 204.02px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image15.png')"
          style="width: 297.40px; height: 204.02px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <ol
      class="c3 lst-kix_list_3-1"
      start="5"
    >
      <li class="c0 c5 li-bullet-0">
        <span class="c2">Estimasi Kerugian ( optional )</span>
      </li>
    </ol>
    <p class="c0 c12">
      <span class="c2">Input ini bersifat optional, form input ini harus diisi angka agar pada detail nanti tidak muncul Nol (0).</span>
    </p>
    <p class="c0 c12 c16">
      <span class="c2" />
    </p>
    <ol
      class="c3 lst-kix_list_3-1"
      start="6"
    >
      <li class="c0 c5 li-bullet-0">
        <span class="c2">Persentase Kerugian </span>
      </li>
    </ol>
    <p class="c0 c12">
      <span class="c2">Input ini bersifat optional, form input ini harus diisi angka agar pada detail nanti tidak muncul Nol (0), maksimal 100.</span>
    </p>
    <p class="c0 c12 c16">
      <span class="c2" />
    </p>
    <ol
      class="c3 lst-kix_list_3-1"
      start="7"
    >
      <li class="c0 c5 li-bullet-0">
        <span class="c2">Estimasi Korban ( optional )</span>
      </li>
    </ol>
    <p class="c0 c12">
      <span class="c2">Input ini bersifat optional, form input ini harus diisi angka agar pada detail nanti tidak muncul Nol (0).</span>
    </p>
    <p class="c0 c12 c16">
      <span class="c2" />
    </p>
    <ol
      class="c3 lst-kix_list_3-1"
      start="8"
    >
      <li class="c0 c5 li-bullet-0">
        <span class="c2">Lokasi ( mandatori )</span>
      </li>
    </ol>
    <p class="c0 c12">
      <span class="c2">Input ini bersifat mandatori.</span>
    </p>
    <ol
      class="c3 lst-kix_list_3-1"
      start="9"
    >
      <li class="c0 c5 li-bullet-0">
        <span class="c2">Status ( mandatori) </span>
      </li>
    </ol>
    <p class="c0">
      <span class="c2">input pilihan ini bersifat mandatori, pilih salah satu pada list yang sudah disediakan.</span>
    </p>
    <p class="c0 c16">
      <span class="c2" />
    </p>
    <ol
      class="c3 lst-kix_list_3-1"
      start="10"
    >
      <li class="c0 c5 li-bullet-0">
        <span class="c2">File Surat ( mandatori)</span>
      </li>
    </ol>
    <p class="c0">
      <span class="c2">Input File ini bersifat mandatori, mampu untuk mengunggah banyak file, per file harus dibawah 32 MB dan total size semua file maksimal dibawah 100 MB. Jika file tidak sesuai dengan peraturan diatas akan muncul error peringatan.</span>
    </p>
    <p class="c0 c16">
      <span class="c2" />
    </p>
    <ol
      class="c3 lst-kix_list_3-1"
      start="11"
    >
      <li class="c0 c5 li-bullet-0">
        <span class="c2">Tag</span>
      </li>
    </ol>
    <p class="c0">
      <span class="c2">Input bersifat optional, silahkan isi dengan text singkat sebagai pembantu kata kunci, bisa diisi banyak.</span>
    </p>
    <p class="c0 c16">
      <span class="c2" />
    </p>
    <ol
      class="c3 lst-kix_list_3-1"
      start="12"
    >
      <li class="c0 c5 li-bullet-0">
        <span class="c2">Deskripsi</span>
      </li>
    </ol>
    <p class="c0">
      <span class="c2">Input bersifat optional, silahkan isi dengan text sebagai detail dokument anda.</span>
    </p>
    <p class="c0 c16">
      <span class="c2" />
    </p>
    <ol
      class="c3 lst-kix_list_3-1"
      start="13"
    >
      <li class="c0 c5 li-bullet-0">
        <span class="c2">Catatan</span>
      </li>
    </ol>
    <p class="c0">
      <span class="c2">Input bersifat optional, silahkan isi dengan text sebagai detail dokument anda.</span>
    </p>
    <p class="c0 c16">
      <span class="c2" />
    </p>
    <p class="c0 c16">
      <span class="c2" />
    </p>
    <p class="c0 c16">
      <span class="c2" />
    </p>
    <ol
      class="c3 lst-kix_list_3-1"
      start="14"
    >
      <li class="c0 c5 li-bullet-0">
        <span class="c2">Detail Kerusakan</span>
      </li>
    </ol>
    <p class="c0">
      <span class="c2">Input bersifat optional, silahkan isi sesuai form yang disediakan, semua form pada detail kerusakan ini bersifat optional, contoh :</span>
    </p>
    <p class="c0">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 474.76px; height: 247.37px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image19.png')"
          style="width: 474.76px; height: 247.37px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c0">
      <span class="c2">Untuk menghapus detail kerusakan selanjutnya, klik tombol &ldquo;hapus&rdquo; disisi kanan (kotak merah) &nbsp;dan menambah detail kerusakan, klik tombol &ldquo;+ Tambah Detail&rdquo; dibawah form (kotak biru)</span>
    </p>
    <ol
      class="c3 lst-kix_list_3-1"
      start="15"
    >
      <li class="c0 c5 li-bullet-0">
        <span class="c2">Donasi</span>
      </li>
    </ol>
    <p class="c0">
      <span class="c2">Input bersifat optional, silahkan isi sesuai dengan yang disediakan, semua form pada donasi ini bersifat optional, contoh :</span>
    </p>
    <p class="c0">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 482.76px; height: 188.10px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image17.png')"
          style="width: 482.76px; height: 188.10px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c0">
      <span class="c2">Untuk menghapus detail kerusakan selanjutnya, klik tombol &ldquo;hapus&rdquo; disisi kanan (kotak merah) &nbsp;dan menambah detail kerusakan, klik tombol &ldquo;+ Tambah Donasi&rdquo; dibawah form (kotak biru)</span>
    </p>
    <p class="c0 c16">
      <span class="c2" />
    </p>
    <ol
      class="c3 lst-kix_list_3-1"
      start="16"
    >
      <li class="c0 c5 li-bullet-0">
        <span class="c2">Korban</span>
      </li>
    </ol>
    <p class="c0">
      <span class="c2">Input bersifat optional, silahkan isi sesuai dengan yang form disediakan, semua form pada korban ini bersifat optional, contoh :</span>
    </p>
    <p class="c0">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 508.94px; height: 495.02px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image18.png')"
          style="width: 508.94px; height: 495.02px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span><span class="c2">&nbsp;</span>
    </p>
    <p class="c0">
      <span class="c2">Untuk menghapus detail kerusakan selanjutnya, klik tombol &ldquo;hapus&rdquo; disisi kanan (kotak merah) &nbsp;dan menambah detail kerusakan, klik tombol &ldquo;+ Tambah Korban&rdquo; dibawah form (kotak biru)</span>
    </p>
    <ul class="c3 lst-kix_list_2-0">
      <li class="c5 c14 li-bullet-0">
        <span class="c1">Klik &ldquo;Simpan&rdquo; untuk menyimpan dokumen, jika terjadi error ada beberapa input yang kurang.</span>
      </li>
    </ul>
    <p class="c14">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 485.14px; height: 545.06px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image20.png')"
          style="width: 485.14px; height: 545.06px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <ul class="c3 lst-kix_list_2-0">
      <li class="c14 c5 li-bullet-0">
        <span class="c1">Bila berhasil akan mucul popup seperti ini :</span>
      </li>
    </ul>
    <p class="c18 c19">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 347.00px; height: 94.00px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image21.png')"
          style="width: 347.00px; height: 94.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <hr style="page-break-before:always;display:none;">
    <p class="c18 c16">
      <span class="c1" />
    </p>
    <h3
      id="h.jxcdg7ihei0p"
      class="c6"
    >
      <span class="c10">Melihat daftar tanggap darurat</span>
    </h3>
    <ul class="c3 lst-kix_list_5-0 start">
      <li
        id="h.gjdgxs"
        class="c14 c5 li-bullet-0"
      >
        <span class="c1">Perhatikan menu sidebar klik &ldquo;Tanggap Darurat&rdquo; untuk memunculkan list sub menu-nya, kemudian pilih dan klik pada &ldquo;Daftar Tanggap Darurat&rdquo;, tunggu sebentar agar web mengalihkan ke halaman form tanggap darurat</span>
      </li>
    </ul>
    <p class="c14">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 526.91px; height: 340.30px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image22.png')"
          style="width: 526.91px; height: 340.30px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <ul class="c3 lst-kix_list_5-0">
      <li class="c14 c5 li-bullet-0">
        <span class="c1">Setalah anda berada pada halaman table tanggap darurat, anda bisa melihat dokumen yang telah anda simpan dengan format singkat</span>
      </li>
    </ul>
    <p class="c18 c19">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 496.65px; height: 248.59px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image23.png')"
          style="width: 496.65px; height: 248.59px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <hr style="page-break-before:always;display:none;">
    <p class="c18 c16">
      <span class="c1" />
    </p>
    <ul class="c3 lst-kix_list_5-0">
      <li class="c14 c5 li-bullet-0">
        <span class="c1">Untuk melihat hasil sebelumnya anda bisa menggunakan fitur pagination untuk membagi table agar data yang ditampilkan tidak berlebih, pada gambar berikut :</span>
      </li>
    </ul>
    <p class="c14">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 460.26px; height: 230.38px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image24.png')"
          style="width: 460.26px; height: 230.38px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c14">
      <span class="c1">Klik &ldquo;&lt;&rdquo; untuk kembali ke halaman sebelumnya, dan Klik &ldquo;&gt;&rdquo; untuk ke halaman selanjutnya </span>
    </p>
    <p class="c18 c19">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 466.64px; height: 233.57px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image25.png')"
          style="width: 466.64px; height: 233.57px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c18 c16">
      <span class="c1" />
    </p>
    <ul class="c3 lst-kix_list_5-0">
      <li class="c14 c5 li-bullet-0">
        <span class="c1">Untuk melanjutkan ke detail anda bisa klik bagian nomor unik pada table, perhatikan pada gambar :</span>
      </li>
    </ul>
    <p class="c14">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 497.98px; height: 249.26px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image26.png')"
          style="width: 497.98px; height: 249.26px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <h3
      id="h.3pfgcseqkcbt"
      class="c6"
    >
      <span class="c10">Melihat detail dokumen tanggap darurat</span>
    </h3>
    <ul class="c3 lst-kix_list_1-2 start">
      <li class="c11 li-bullet-1">
        <span class="c1">Kilk nomor unik pada daftar / table untuk melanjutkan ke detail</span>
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image26.png')"
          style="width: 497.98px; height: 249.26px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </li>
    </ul>
    <p class="c18 c16">
      <span class="c1" />
    </p>
    <ul class="c3 lst-kix_list_1-2">
      <li class="c22 li-bullet-1">
        <span class="c1">Kemudian anda akan dipindahkan ke halaman detail, anda bisa melihat semua detail dokumen sesuai dengan yang diisi</span>
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image1.png')"
          style="width: 570.62px; height: 700.17px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </li>
    </ul>
    <h3
      id="h.gbmzb2xa7kdg"
      class="c6"
    >
      <span class="c10">Mengubah dokumen tanggap darurat</span>
    </h3>
    <p class="c17 c12">
      <span class="c1">Anda bisa pada halaman detail atau daftar tanggap darurat &nbsp;untuk mengubah dokumen yang telah anda simpan.</span>
    </p>
    <p class="c12 c17">
      <span class="c1">Perlu diperhatikan bahwa peraturan pada pengunaan input yang ada pada form sesuai dengan pada langkah- langkah yang ada di &ldquo;penambahan dokumen tanggap darurat&rdquo;</span>
    </p>
    <p class="c17 c12 c16">
      <span class="c1" />
    </p>
    <p class="c17">
      <span class="c1">langkah-langkah sebagai berikut :</span>
    </p>
    <ul class="c3 lst-kix_list_1-2">
      <li class="c5 c15 li-bullet-0">
        <span class="c1">Masuk ke detail tanggap darurat, kemudian klik tombol &ldquo;Edit Dokumen&rdquo;</span>
      </li>
    </ul>
    <p class="c18 c20">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 624.00px; height: 764.67px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image2.png')"
          style="width: 624.00px; height: 764.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c18 c16 c20">
      <span class="c1" />
    </p>
    <ul class="c3 lst-kix_list_1-2">
      <li class="c7 c5 li-bullet-0">
        <span class="c1">Atau lewat daftar / table dengan menggunakan tombol aksi, contoh pada gambar :</span>
      </li>
    </ul>
    <p class="c7">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 568.59px; height: 119.18px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image3.png')"
          style="width: 568.59px; height: 119.18px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c7">
      <span class="c1">Klik icon &ldquo;titik tiga&rdquo;, kemudian pilih dan klik &ldquo;Edit&rdquo;, anda akan dialihkan ke halaman form pengubahan untuk merubah isi dokumen</span>
    </p>
    <ul class="c3 lst-kix_list_1-2">
      <li class="c7 c5 li-bullet-0">
        <span class="c1">Kemudian ubah sesuai apa yang anda inginkan pada dokumen yang telah anda simpan sebelumnya, contoh :

        </span>
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image4.png')"
          style="width: 555.03px; height: 681.04px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </li>
    </ul>
    <p class="c15 c16">
      <span class="c1" />
    </p>
    <hr style="page-break-before:always;display:none;">
    <p class="c16 c18">
      <span class="c1" />
    </p>
    <ul class="c3 lst-kix_list_1-2">
      <li class="c7 c5 li-bullet-0">
        <span class="c1">Klik Simpan untuk menyimpan perubahan, akan muncul popup kecil dibawah untuk penanda berhasil, contoh :</span>
      </li>
    </ul>
    <p class="c17 c16">
      <span class="c1" />
    </p>
    <p class="c7">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 298.27px; height: 94.94px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image5.png')"
          style="width: 298.27px; height: 94.94px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c4">
      <span class="c1" />
    </p>
    <ul class="c3 lst-kix_list_1-2">
      <li class="c5 c7 li-bullet-0">
        <span class="c1">Untuk melihat hasil perubahan anda bisa menuju ke halaman daftar atau detail</span>
      </li>
    </ul>
    <p class="c7">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 454.24px; height: 207.66px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image6.png')"
          style="width: 454.24px; height: 207.66px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c4">
      <span class="c1" />
    </p>
    <p class="c7">
      <span class="c1">Pada detail anda bisa melihat riwayat dokumen, apa bila mendapat perubahan</span>
    </p>
    <p class="c15">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 487.58px; height: 153.46px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-5/image7.png')"
          style="width: 487.58px; height: 153.46px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c13">
      <span class="c1" />
    </p>
  </b-card>
</template>

<script>
import { BImg, BCard } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BCard,
  },
}
</script>

<style lang="scss" scoped>
.content-body img {
  display: block;
  max-width: 100%;
  padding-left: 35px;
  width: unset !important;
}

.content-body ol {
  list-style: circle;
  margin-left: 21px;
  span {
    font-weight: 600;
    font-size: 13px;
  }
}
</style>
